<template>
  <v-card
    class="pa-6"
    height="100%"
  >
    <v-card-title class="notification-title pa-0">
      Уведомление
    </v-card-title>

    <v-btn-toggle
      mandatory
      class="mt-6"
    >
      <v-btn
        v-for="lang in languageList"
        :key="lang"
        :value="lang"
        small
        text
        color="primary"
        @click="setSelectedLanguage(lang)"
      >
        {{ lang.toUpperCase() }}
      </v-btn>
    </v-btn-toggle>

    <v-text-field
      v-model="notification[selectedLanguage].title"
      label="Заголовок"
      outlined
      dense
      hide-details
      :spellcheck="false"
      class="mt-6"
    />

    <v-autocomplete
      v-model="typeUpdate"
      label="Тип обновления"
      :items="updatesList"
      dense
      outlined
      hide-details
      item-text="name"
      return-object
      class="mt-6"
    />

    <v-text-field
      v-model="updateVersion"
      label="Версия"
      outlined
      dense
      hide-details
      :spellcheck="false"
      class="mt-6"
    />

    <div
      v-if="editor"
      class="d-flex align-center mt-8 mb-3 notifications-editor-container"
    >
      <v-container class="pa-0 ma-0 d-flex align-center notifications-parameters-group">
        <v-btn
          v-for="(button, index) in editorButtons"
          :key="index"
          icon
          width="24"
          height="24"
          @click="editorButtonClick(button.action, button.method)"
        >
          <v-icon
            :size="16"
            :color="editor.isActive(button.action) ? 'primary' : 'white'"
          >
            {{ button.icon }}
          </v-icon>
        </v-btn>
      </v-container>
    </div>

    <editor-content
      :editor="editor"
      class="notifications-editor"
    />

    <v-card-actions class="mt-5">
      <v-spacer />
      <v-btn
        color="primary"
        :disabled="!checkAllKeysHaveValue(notification)"
        @click="addNotification"
      >
        Отправить уведомление
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { Underline } from '@tiptap/extension-underline';
import languageList from '@/constants/notifications/languagesList';
import updatesList from '@/constants/notifications/updatesList';
import editorButtons from '@/constants/notifications/editorButtons';
import notificationsDefaultValue from '@/constants/notifications/notificationDefaultValue';

export default {
  name: 'Notifications',
  components: {
    EditorContent,
  },
  data() {
    return {
      sanitizeHtml,
      editor: null,
      notification: notificationsDefaultValue,
      selectedLanguage: 'en',
      typeUpdate: '',
      updateVersion: '',
    };
  },
  computed: {
    languageList() {
      return languageList;
    },

    updatesList() {
      return updatesList;
    },

    editorButtons() {
      return editorButtons;
    },
  },
  watch: {
    selectedLanguage(newLang) {
      const text = this.notification[newLang]?.text || '';
      this.editor.commands.setContent(text);
    },
  },
  async created() {
    await this.newEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    setSelectedLanguage(lang) {
      this.selectedLanguage = lang;
    },

    addNotification() {
      if (!this.checkAllKeysHaveValue(this.notification)) return;
      const requestData = {
        section: this.typeUpdate,
        data: { ...this.notification },
      };

      if (this.typeUpdate === 'updateElectron') {
        requestData.data.electronVersion = this.updateVersion;
      }

      if (this.typeUpdate === 'updateBrowser') {
        requestData.data.browserVersion = this.updateVersion;
      }
      try {
        this.api.post('/admin/notifications', requestData);

        this.$store.dispatch('main/alert', {
          color: 'success',
          message: 'Опубликовано',
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: 'Произошла ошибка. Пожалуйста, попробуй еще раз',
        });
      }
    },

    checkAllKeysHaveValue(notification) {
      const isNotificationValid = Object.entries(notification).every(([key, value]) => {
        if (key && typeof value === 'object' && value !== null) {
          return this.checkAllKeysHaveValue(value);
        }
        return value !== '';
      });

      return isNotificationValid && this.typeUpdate && this.updateVersion;
    },

    editorButtonClick(action, method) {
      this.editor.chain().focus()[method]().run();
    },

    newEditor() {
      this.editor = new Editor({
        disablePasteRules: ['italic'],
        disableInputRules: ['italic'],
        autoFocus: true,
        extensions: [
          StarterKit,
          Underline,
        ],
        onUpdate: ({ editor }) => {
          this.notification[this.selectedLanguage].text = sanitizeHtml(editor.getHTML(), {
            allowedTags: ['p', 'strong', 'em', 'u', 's', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'br'],
          });
        },
      });

      this.handleChangeNotificationContent();
    },

    handleChangeNotificationContent() {
      const { text } = this.notification[this.selectedLanguage];

      if (text) {
        this.editor.commands.setContent(text);
      } else if (text === null) {
        this.notification[this.selectedLanguage].text = 'none';
      }
    },
  },
};
</script>

<style lang="scss">
.notification-title {
  font-size: 34px;
}

.notifications-editor .ProseMirror {
  min-height: 90px;
  height: 500px;
  border: solid 1px #626262;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  overflow-y: auto;
}

.notifications-editor .ProseMirror::-webkit-scrollbar {
  display: none;
}

.notifications-editor .ProseMirror p {
  margin-bottom: 0 ;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.notifications-editor .ProseMirror-focused {
  border-color: #2196F3;
}

.notifications-editor .ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  color: var(--v-old-input-foreground-default-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.notifications-parameters-group {
  min-width: fit-content;
  width: fit-content;
  gap: 6px;
}

.notifications-editor-container {
  gap: 20px;
}
</style>
